import { navigate } from "gatsby";
import { ReactNode, useEffect, useMemo, useState } from "react";

import pollStatus from "../../utils/poll_status";
import { useResultUuid } from "../result/use-cases/result-use-cases";

export type Callback = (actions: { waitAndRetry: () => void; stopPolling: () => void }) => void;

interface PollingLoaderProps {
  nextUrl?: string;
  statusesCallbacks?: Record<string, Callback>;
  children: ReactNode;
  defaultCallback?: () => void;
  timeoutCallback?: () => void;
  timeoutToNavigate?: number;
  isWaitingToNavigate?: boolean;
}

const PollingLoader = ({
  nextUrl,
  statusesCallbacks,
  children,
  defaultCallback,
  timeoutCallback,
  timeoutToNavigate = 500,
  isWaitingToNavigate = false,
}: PollingLoaderProps): JSX.Element => {
  const resultUuid = useResultUuid();
  const [isPollingDone, setIsPollingDone] = useState(false);
  const stopPolling = () => setIsPollingDone(true);

  const wrappedCallbacks = useMemo(() => {
    const wrappedCallbacks = {};
    for (const status in statusesCallbacks) {
      wrappedCallbacks[status] = (waitAndRetry: () => void) => {
        statusesCallbacks[status]({ stopPolling, waitAndRetry });
      };
    }

    return wrappedCallbacks;
  }, [statusesCallbacks]);

  useEffect(() => {
    if (resultUuid && !isPollingDone) {
      pollStatus(
        `${process.env.GATSBY_API_BASE}/results/${resultUuid}/`,
        wrappedCallbacks,
        defaultCallback ?? stopPolling,
        timeoutCallback ?? stopPolling
      );
    }
  }, [resultUuid, isPollingDone, wrappedCallbacks, defaultCallback, timeoutCallback]);

  if (isPollingDone && !isWaitingToNavigate && nextUrl) {
    setTimeout(() => navigate(nextUrl), timeoutToNavigate);
  }

  return <>{children}</>;
};

export default PollingLoader;
